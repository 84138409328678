import React, { useState, useEffect } from "react";
import Header from "../Header";
import { Table } from "antd";
import { ConstructorEventGet, ConstructorEventPost } from "../../Event/event";
import { FetchDemoEnquiryDetails, FetchSessionDetails, UpdateDemoEnquiryDetails } from "../../api/aws-api";
import { notifySuccess, notifyError } from "../../Common/CommonFunction";


function DemoDetails() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [demoData, setDemodata] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [comments, setComments] = useState("")
    const [followUpDate, setFollowUpDate] = useState("")
    // const [todayDate, setTodayDate] = useState('')


    const columns = [
        { title: "FollowUpDate", dataIndex: "date", align: "center" },
        { title: "LeadDate", dataIndex: "leadgenerateddate", align: "center" },
        { title: "StudentName", dataIndex: "name", align: "center" },
        { title: "StudentAge", dataIndex: "age", align: "center" },
        { title: "BDE-Email", dataIndex: "email", align: "center" },
        { title: "Country", dataIndex: "country", align: "center" },
        { title: "Has Laptop", dataIndex: "has_laptop", align: "center" },
        { title: "Mobile", dataIndex: "mobileNumber", align: "center" },
        { title: "Description", dataIndex: "comment", align: "center" },
        {
            title: "More Actions",
            align: "center",
            render: (data) => (
                <button onClick={() => updateDemodetails(data)} style={{ backgroundColor: "red", borderRadius: "5px", padding: "5px", color: "white" }}>
                    Update
                </button>
            ),
        },
    ];

    useEffect(() => {
        // getTodayDate()
        fetchDemodata();
    }, []);


    const fetchDemodata = async () => {
        const dateObj = new Date(); // Converts string to Date object
        const formattedDate = dateObj.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
        });

        const dateInputFormat = dateObj.toISOString().split('T')[0];
        setSelectedDate(dateInputFormat)


        const responsedata = await ConstructorEventGet(FetchDemoEnquiryDetails, {
            date: formattedDate,
        });
        setData(responsedata)
        setDemodata(responsedata)
    };


    const fetchParticularDateDemoDetails = async (e) => {

        if (!e.target.value) {
            fetchDemodata()
        } else {
            setSelectedDate(e.target.value)

            const dateObj = new Date(e.target.value); // Converts string to Date object
            const formattedDate = dateObj.toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
            });
            const responsedata = await ConstructorEventGet(FetchDemoEnquiryDetails, {
                date: formattedDate,
            });
            setDemodata(responsedata)

        }

    }

    const fetchselectedDateDemoDetails = async (date) => {




        const dateObj = new Date(date); // Converts string to Date object
        const formattedDate = dateObj.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
        });
        const responsedata = await ConstructorEventGet(FetchDemoEnquiryDetails, {
            date: formattedDate,
        });
        setDemodata(responsedata)



    }



    const showModal = (data) => {
        setSelectedData(data);
        setIsModalVisible(true);
        document.body.classList.add("modal-open"); // Prevent scrolling when modal is open
    };

    const handleOk = () => {
        setIsModalVisible(false);
        document.body.classList.remove("modal-open");
        updateDemoEnquiryDetailsInDynamo(selectedData)
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        document.body.classList.remove("modal-open");
    };

    const updateDemodetails = (data) => {
        showModal(data);
    };

    const updateDemoEnquiryDetailsInDynamo = async (data) => {
        const dateObj = new Date(followUpDate); // Converts string to Date object
        const formattedDate = dateObj.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
        });

        const updatedDetails = {
            ...data,
            date: formattedDate,
            comment: comments
        };

        let Params = {
            id: data.id,
            data: updatedDetails
        }


        const responsedata = await ConstructorEventPost(UpdateDemoEnquiryDetails, Params)
        if (responsedata.statusCode == 200) {
            fetchselectedDateDemoDetails(selectedDate)
            notifySuccess("Details Updated Succesfully")
        } else {
            notifyError("Error while updating the Demo Data")
        }

    }



    return (
        <div>
            <Header />
            <div className="nav-tab-wrapper tabs section-padding mt-5">
                <div className="container mt-5">
                    <div className="mini-title">
                        Demo Details
                        <div className="small-btn ml-4">
                            <input type="date" onChange={(e) => fetchParticularDateDemoDetails(e)} value={selectedDate} />
                        </div>
                    </div>
                    <div className="col-span-12 mt-2">
                        <div className="bg-white shadow-box7 mt-5 rounded-md">
                            <Table className="" columns={columns} dataSource={demoData} rowKey={(record) => record.key} scroll={{ x: 1300 }} />
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL */}
            {
                isModalVisible && (
                    <div className="modal-overlay" onClick={handleCancel}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <h3>Update Details</h3>
                            <label className="mt-2" style={{ fontWeight: "bold", color: "black" }}>
                                Description:
                            </label>
                            <br />
                            <textarea className="mt-2 form-control w-100" type="text" onChange={(e) => setComments(e.target.value)}></textarea>
                            <br />
                            <label className="mt-2 fw-bold" style={{ fontWeight: "bold", color: "black" }}>
                                Follow Up Date:
                            </label>
                            <input className="mt-2 form-control" type="date" onChange={(e) => setFollowUpDate(e.target.value)} />

                            <div className="mt-4">
                                <button className="btn btn-secondary mr-2" onClick={handleOk}>
                                    Update
                                </button>
                                <button className="btn btn-primary" onClick={handleCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    );
}

export default DemoDetails;