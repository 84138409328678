/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/newly-added/Anglosone_logo_mobile.png";
import { codomologoMoreWidth, LOGOSingleLine, codomo_logo_mobile } from "../constant/images";
import { googleLogout } from '@react-oauth/google';

import { AuthenticationState } from '../App';

const MobileMenu = ({ activeMenu, setActiveMenu, downloadoptions }) => {
  const { setuserAuthenticated, setVerifiedAsMentor, verifiedAsMentor, verifiedAsAdmin, setVerifiedAsAdmin, verifiedAsBDE, setVerifiedAsBDE } = useContext(AuthenticationState)
  const [showCourseMenu, setShowCourseMenu] = useState(false);
  const [showDownloadMenu, setShowDownloadMenu] = useState(false);
  const [showMentorMenu, setShowMentorMenu] = useState(false);
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const [showCRMMenu, setShowCRMMenu] = useState(false);
  const navigate = useNavigate()
  const handleShowCourseMenu = () => {
    setShowCourseMenu(!showCourseMenu);
  };
  const handleShowDownloadMenu = () => {
    setShowDownloadMenu(!showDownloadMenu);
  };
  const handleShowMentorMenu = () => {
    setShowMentorMenu(!showMentorMenu);
  };
  const handleShowAdminMenu = () => {
    setShowAdminMenu(!showAdminMenu);

  };

  const handleShowCRMMenu = () => {
    setShowCRMMenu(!showCRMMenu)
  }
  const logOut = () => {
    googleLogout();
    navigate('/signIn');
    localStorage.clear();
    sessionStorage.clear();
    setuserAuthenticated(false)
    setVerifiedAsMentor(false)
    setVerifiedAsAdmin(false)
    setVerifiedAsBDE(false)
  };


  return (
    <>
      <div className="openmobile-menu fixed top-0 h-screen pt-10 pb-6 bg-white shadow-box2 w-[320px] overflow-y-auto flex flex-col z-[999] active-mobile-menu  ">
        <div className="flex justify-between px-6 flex-none">
          <Link
            to={"/codomo/home"}
            className="brand-logo flex-none mr-10 "
          >
            <img src={codomo_logo_mobile} alt="logo" />
          </Link>
          <span
            className=" text-3xl text-black cursor-pointer rt-mobile-menu-close"
            onClick={() => {
              setActiveMenu(!activeMenu);
            }}
          >
            <iconify-icon icon="fe:close"></iconify-icon>
          </span>
        </div>
        <div className="mobile-menu mt-6 flex-1 ">
          <ul className="menu-active-classNamees">
            <li>
              <Link to="/codomo/home">Home</Link>
            </li>
            <li
              className={`menu-item-has-children ${showCourseMenu ? "open" : ""}`}
              onClick={handleShowCourseMenu}
            >
              <a >Course</a>
              <ul
                className="sub-menu"
                style={
                  showCourseMenu ? { display: "block" } : { display: "none" }
                }
              >
                {downloadoptions.map((item, index) => (
                  <li>
                    <Link to="/codomo/course" state={{ "course": item }}>{item.course_name}</Link>
                  </li>
                ))}
              </ul>
            </li>
            <li
              className={`menu-item-has-children ${showDownloadMenu ? "open" : ""}`}
              onClick={handleShowDownloadMenu}
            >
              <a>Downloads</a>
              <ul
                className="sub-menu"
                style={
                  showDownloadMenu ? { display: "block" } : { display: "none" }
                }
              >
                {downloadoptions.map((item, index) => (
                  <li>
                    <Link to="/codomo/course/downloads" state={{ course: item }}>{item.course_name}</Link>
                  </li>
                ))}
              </ul>
            </li>
            {verifiedAsMentor ?
              <li
                className={`menu-item-has-children ${showMentorMenu ? "open" : ""}`}
                onClick={handleShowMentorMenu}
              >
                <a >Mentor</a>
                <ul
                  className="sub-menu"
                  style={
                    showMentorMenu ? { display: "block" } : { display: "none" }
                  }
                >

                  <li><Link to="/codomo/mentor/activities">Batch management</Link>
                  </li>
                  <li><Link to="/codomo/mentor/notification">Notification Service</Link>
                  </li>
                  <li><Link to="/codomo/mentor/addblog">Add Blog</Link>
                  </li>

                </ul>
              </li> : ""}
            {verifiedAsAdmin ?
              <li
                className={`menu-item-has-children ${showAdminMenu ? "open" : ""}`}
                onClick={handleShowAdminMenu}
              >
                <a >Admin</a>
                <ul
                  className="sub-menu"
                  style={
                    showAdminMenu ? { display: "block" } : { display: "none" }
                  }
                >
                  <li><Link to="/codomo/admin/usermanagement">User Management</Link></li>
                  <li><Link to="/codomo/admin/coursemanagement">course Management</Link></li>
                </ul>
              </li> : ""}
            {/* <li>
              <Link to={"/codomo/blog"}>Blog </Link>
            </li> */}
            {verifiedAsAdmin || verifiedAsBDE ?
              <li
                className={`menu-item-has-children ${showAdminMenu ? "open" : ""}`}
                onClick={handleShowCRMMenu}
              >
                <a >CRM</a>
                <ul
                  className="sub-menu"
                  style={
                    showCRMMenu ? { display: "block" } : { display: "none" }
                  }
                >
                  <li><Link to="/codomo/admin/DemoDetails">Dashboard</Link></li>
                  <li><Link to="/codomo/admin/DemoDetailsForm">Demo Details Form</Link></li>
                </ul>
              </li> : ""}


            <li>
              <Link to={"/codomo/contactus"}>Contacts </Link>
            </li>
            <li onClick={logOut}>
              <a>Logout</a>
            </li>
          </ul>
        </div>
      </div>{" "}
      <div className={`rt-mobile-menu-overlay ${activeMenu && "active"}`}></div>
    </>
  );
};

export default MobileMenu;
