import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../Header";
import { useEffect, useState } from "react";
import { FetchStudentDetails } from "../../api/aws-api";

import { ConstructorEventPost, ConstructorEventGet } from "../../Event/event";
import { notifySuccess, notifyError } from "../../Common/CommonFunction";



function DemoDetailsForm() {

    const navigate = useNavigate()

    const [name, setName] = useState("")
    const [number, setNumber] = useState("")
    const [age, setAge] = useState("")
    const [date, setDate] = useState("")
    const [time, setTime] = useState("")
    const [hasLaptop, setHasLaptop] = useState("")
    const [bdeList, setBdeList] = useState([])
    const [bdeEmail, setBdeEmail] = useState("")
    const [userType, setUserType] = useState("")
    const [country, setCountry] = useState("")




    useEffect(() => {
        fetchStudentDetails()
    }, [])


    const fetchStudentDetails = async () => {

        const userData = JSON.parse(sessionStorage.getItem("UserData"))
        const userType = userData.user_type
        const userName = userData.user_email
        const response = await ConstructorEventGet(FetchStudentDetails);
        const bdelist = response.filter((ele) => {
            return ele.user_type == "BDE"
        })

        if (userType === "BDE") {
            setBdeEmail(userName)
        }
        setBdeList(bdelist)

        setUserType(userType)
    }

    const saveDemoDetails = async () => {


        if (number != "" && bdeEmail != "" && date != "") {
            const selectedDate = new Date(date);
            const formattedDate = selectedDate.toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
            });

            const [hours, minutes] = time.split(":");
            const formattedTime = `${(hours % 12) || 12}:${minutes} ${hours >= 12 ? "PM" : "AM"}`;

            const eventData = {
                "studentName": name,
                "studentAge": age,
                "parentsMobileNumber": number,
                "hasLaptop": hasLaptop,
                "date": formattedDate,
                "time": time != "" ? formattedTime : "",
                "country": country,
                "bdeEmail": bdeEmail,
            }


            console.log("the events are", eventData)


            const responsedata = await ConstructorEventPost("https://tan6tgboda.execute-api.ap-south-1.amazonaws.com/dev/SaveDemoDetails", eventData)

            console.log("the responsedat for eveeeeeeeee", responsedata)

            if (responsedata.statusCode === 200) {
                setName("")
                setAge("")
                setNumber("")
                setDate("")
                setTime("")
                setHasLaptop("")
                setBdeEmail("")
                setCountry("")
                notifySuccess("Saved Successfully")
            } else {
                notifyError("Error while scheduling lesson")
            }

        } else {
            notifyError("Please fill the required fields")
        }


    }

    return (
        <div>
            <Header />
            <div className="nav-tab-wrapper tabs section-padding mt-5">
                <div className="container mt-5">
                    <div className="mt-2 mini-title">Upload Demo Details</div>
                    {/* <div className=" grid grid-col-12 gap-[30px] bg-white shadow-box7 p-8 rounded-md mt-8">
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                            <input
                                type="text"
                                className="from-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)} required
                            />
                            <span className={`input-placeholder ${name && 'active'}`}>
                                Student Name
                            </span>
                        </div>
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                            <input
                                type="number"
                                className="from-control"
                                value={age}
                                onChange={(e) => setAge(e.target.value)}
                            />
                            <span className={`input-placeholder ${age && 'active'}`}>
                                Student Age
                            </span>
                        </div>
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                            <input
                                type="text"
                                className="from-control"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                required
                            />
                            <span className={`input-placeholder ${number && 'active'}`}>
                                Phone (with Country Code) *
                            </span>
                        </div>
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                            <input
                                type="text"
                                className="from-control"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                required
                            />
                            <span className={`input-placeholder ${country && 'active'}`}>
                                Country *
                            </span>
                        </div>
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                            <input
                                type="date"
                                className="from-control"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                required
                            />
                            {date ? <span className={`input-placeholder ${date && 'active'}`}>
                                LeadDate *
                            </span> : ""}
                        </div>
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                            <input
                                type="time"
                                className="from-control"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                                required
                            />
                            {time ?
                                <span className={`input-placeholder ${time && 'active'}`}>
                                    Time *
                                </span> : ""}
                        </div>

                        <div className={hasLaptop != "" ? "xl:col-span-4 lg:col-span-2 sm:col-span-12 col-span-4" : "mt-5 xl:col-span-2 lg:col-span-2 sm:col-span-12 col-span-4"}>

                            {hasLaptop != "" ? <label>Has Laptop</label> : ""}
                            <select
                                className="from-control p-4 bg-[#ECECEC] text-[#827878]"
                                onChange={(e) => setHasLaptop(e.target.value)}
                                required
                            >
                                <option value="">Has Laptop*</option>
                                <option key="yes" value="Yes">Yes</option>
                                <option key="no" value="No">No</option>
                            </select>
                        </div>

                        <div className={hasLaptop != "" ? "xl:col-span-4 lg:col-span-2 sm:col-span-12 col-span-4" : "mt-5 xl:col-span-2 lg:col-span-2 sm:col-span-12 col-span-4"}>

                            {bdeName != "" ? <label>BDE</label> : ""}
                            <select
                                className="from-control p-4 bg-[#ECECEC] text-[#827878]"
                                onChange={(e) => setBdeName(e.target.value)}
                                required
                            >

                                {userType === "admin" ?
                                    <>
                                        <option value="">Select BDE*</option>
                                        {bdeList.map((ele, index) => (
                                            <option key={index} value={ele.name}>
                                                {ele.name}
                                            </option>
                                        ))}</>
                                    : <option>{bdeName}</option>}
                            </select>
                        </div>


                        <div className=" mt-8 xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                            <button className="btn btn-black" onClick={() => navigate("/codomo/admin/DemoDetails")} >CANCEL</button>
                            <button className="btn ml-4 btn-primary" onClick={saveDemoDetails}>SAVE</button>
                        </div>
                    </div> */}

                    <div

                        className="grid grid-cols-1 md:grid-cols-2 gap-[30px] mt-6"
                    >
                        <div className="input-container">
                            <input
                                type="text"
                                className=" from-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <span className={`input-placeholder ${name && 'active'}`}>
                                Student Name
                            </span>
                        </div>
                        <div className="input-container">
                            <input
                                type="number"
                                className=" from-control"
                                value={age}
                                onChange={(e) => setAge(e.target.value)}
                                required
                            />
                            <span className={`input-placeholder ${age && 'active'}`}>
                                Student Age
                            </span>

                        </div>
                        <div className="input-container">
                            <input
                                type="phone"
                                className="from-control"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                required
                            />
                            <span className={`input-placeholder ${number && 'active'}`}>
                                Mobile Number (with Country Code) *
                            </span>

                        </div>
                        <div className="input-container">
                            <input
                                type="text"
                                className="from-control"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                required
                            />
                            <span className={`input-placeholder ${country && 'active'}`}>
                                Country
                            </span>

                        </div>
                        <div className="input-container">
                            <input

                                type="date"
                                className="from-control"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                required
                            />
                            {date ? <span className={`input-placeholder ${date && 'active'}`}>
                                LeadDate *
                            </span> : ""}

                        </div>
                        <div className="input-container">
                            <input
                                type="time"
                                className="from-control"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}

                            />
                            {time ?
                                <span className={`input-placeholder ${time && 'active'}`}>
                                    Time
                                </span> : ""}
                        </div>
                        <div className="mt-5">
                            {hasLaptop != "" ? <label>Has Laptop</label> : ""}
                            <select
                                className="from-control p-4 bg-[#ECECEC] text-[#827878]"
                                onChange={(e) => setHasLaptop(e.target.value)}
                                value={hasLaptop}
                                required
                            >
                                <option value="">Has Laptop</option>
                                <option key="yes" value="Yes">Yes</option>
                                <option key="no" value="No">No</option>
                            </select>
                        </div>
                        <div className="mt-5">

                            {userType === "admin" ?
                                <div>
                                    {bdeEmail != "" && userType === "admin" ? <label>BDE</label> : ""}
                                    <select
                                        className="from-control p-4 bg-[#ECECEC] text-[#827878]"
                                        onChange={(e) => setBdeEmail(e.target.value)}
                                        value={bdeEmail}
                                        required
                                    >


                                        <>
                                            <option value="">Select BDE*</option>
                                            {bdeList.map((ele, index) => (
                                                <option key={index} value={ele.email}>
                                                    {ele.name}
                                                </option>
                                            ))}</>

                                    </select>
                                </div>

                                : <div className="input-container">
                                    <input className="from-control" value={bdeEmail} />
                                    {bdeEmail ?
                                        <span className={`input-placeholder ${bdeEmail && 'active'}`}>
                                            BDE *
                                        </span> : ""}
                                </div>
                            }
                        </div>

                        <button className="mt-5 btn btn-primary mt-[10px]" type="submit" name="submit" onClick={saveDemoDetails}>
                            Submit
                        </button>
                    </div>



                </div>
            </div>
        </div>
    )

}

export default DemoDetailsForm;