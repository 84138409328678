import { Navigate, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState, createContext, useContext } from 'react';
import HomePage from "./Components/MainPage/HomePage";
import SignIn from "./Components/MainPage/SignIn";
import SignUp from "./Components/MainPage/SignUp"
import Course from "./Components/MainPage/Course";
import AboutOne from "./Components/MainPage/AboutOne"
import EmailConfirm from "./Components/MainPage/EmailConfirm";
import ContactUs from "./Components/MainPage/ContactUs"
import Downloads from "./Components/MainPage/Downloads"
import ErrorPage from "./Components/404ErrorPage";
import MentorActivities from "./Components/MainPage/MentorActivities";
import Authentication from "./Components/Authentication/Authentication";
import AddBatch from "./Components/MainPage/AddBatch";
import AddStudent from "./Components/MainPage/AddStudent"
import Blog from "./Components/MainPage/Blog"
import AddBlog from "./Components/MainPage/AddBlog";
import EditBatch from "./Components/MainPage/EditBatch";
import EditProfile from "./Components/MainPage/EditProfile";
import UserManagement from "./Components/MainPage/UserManagement";
import CreateNewUser from "./Components/MainPage/CreateNewUser";
import CourseManagement from "./Components/MainPage/CourseManagement";
import CreateNewCourse from "./Components/MainPage/CreateNewCourse";
import ActivityManagement from "./Components/MainPage/ActivityManagement";
import CourseDownloadsManagement from "./Components/MainPage/CourseDownloadsManagement";
import SubActivityManagement from "./Components/MainPage/SubActivityManagement";
import AdvancedCourse from "./Components/MainPage/AdvancedCourse";
import CourseMappingManagement from "./Components/MainPage/CourseMappingManagement";
import OneCompilerIframe from "./Components/MainPage/OneCompilerIframe";
import Admiration from "./Components/MainPage/Admiration";
import Popup from "./Components/MainPage/Popup";
import NotificationService from "./Components/MainPage/NotificationService"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthenticateUser, FetchHomePageDetails } from './api/aws-api';
import { ConstructorEventPost, ConstructorEventGet } from './Event/event';
import ActivityOne from "./Components/MainPage/ActivityOne";
import ActivityOneOne from "./Components/MainPage/ActivityOneOne";
import ActivityTwo from "./Components/MainPage/ActivityTwo";
import ActivityTwoOne from "./Components/MainPage/ActivityTwoOne";
import ActivityThree from "./Components/MainPage/ActivityThree";
import ActivityFour from "./Components/MainPage/ActivityFour";
import ActivityNine from "./Components/MainPage/ActivityNine";
import StoryTellerActivity from "./Components/MainPage/StoryTellerActivity";
import StoryTellerActivityOne from "./Components/MainPage/StoryTellerActivityOne";
import ActivitySeven from "./Components/MainPage/ActivitySeven"
import ActivityEight from "./Components/MainPage/ActivityEight"
import ActivityFive from "./Components/MainPage/ActivityFive"
import ActivitySix from "./Components/MainPage/ActivitySix"
import ActivityTen from "./Components/MainPage/ActivityTen"
import ActivityEleven from "./Components/MainPage/ActivityEleven"
import AppLabSessionone from "./Components/MainPage/AppLabSessionone"
import AppLabSessionthree from "./Components/MainPage/AppLabSessionthree"
import Webusb from "./Components/MainPage/Webusb"
import AppLabSessionfour from "./Components/MainPage/AppLabSessionfour"
import AppLabSessionfive from "./Components/MainPage/AppLabSessionfive"
import AppLabSessionsix from "./Components/MainPage/AppLabSessionsix"
import AppLabSessionseven from "./Components/MainPage/AppLabSessionseven"
import AppLabSessioneight from "./Components/MainPage/AppLabSessioneight"
import AppLabSessionnine from "./Components/MainPage/AppLabSessionnine"
import AppLabSessionten from "./Components/MainPage/AppLabSessionten"
import AssiginingCourseToMentor from "./Components/MainPage/AssiginingCourseToMentor"
import AppLabSessioneleven from "./Components/MainPage/AppLabSessioneleven"
import VideoCallActivity from "./Components/MainPage/VideoCallActivity";
import VoiceCallActivity from "./Components/MainPage/VoiceCallActivity";
import ChatScreen from "./Components/MainPage/ChatScreen";
import GoogleMeet from "./Components/MainPage/GoogleMeet";
import BatchManagement from "./Components/MainPage/BatchManagement";
import AssignBatchToTheMentor from "./Components/MainPage/AssignBatchToMentor";
import AccountsManagement from "./Components/MainPage/AccountsManagement";
import CreateNewBatch from "./Components/MainPage/CreateNewBatch";
import DemoDetails from "./Components/MainPage/DemoDetails";
import DemoDetailsForm from "./Components/MainPage/DemoDetailsForm";




export const AuthenticationState = createContext();

function App() {
  const [userAuthenticated, setuserAuthenticated] = useState(false)
  const [verifiedAsMentor, setVerifiedAsMentor] = useState(false)
  const [verifiedAsAdmin, setVerifiedAsAdmin] = useState(false)
  const [verifiedAsBDE, setVerifiedAsBDE] = useState(false)
  const location = useLocation();
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname !== "/signUp") {
      const lastLoginTime = localStorage.getItem('lastLoginTime')
      const authToken = localStorage.getItem('authToken')
      console.log(location.pathname)
      console.log("triggered")
      if (authToken && (Date.now() - lastLoginTime) < 24 * 60 * 120 * 1000 && !userAuthenticated) {
        CheckStatus(authToken)
        console.log("app triggered")
      }
      else if (authToken && (Date.now() - lastLoginTime) < 24 * 60 * 120 * 1000 && userAuthenticated) { }
      else if (authToken && (Date.now() - lastLoginTime) > 24 * 60 * 120 * 1000 && userAuthenticated) {
        setuserAuthenticated(false)
        navigate("/signIn")
        console.log("user time out")
      }
      else {
        setuserAuthenticated(false)
      }
    }
  }, [location.pathname]
  );
  const CheckStatus = async (authToken) => {
    const storedData = localStorage.getItem('UserKey');
    if (storedData !== "undefined" && storedData !== "null" && storedData !== undefined && storedData !== null) {
      const params = JSON.parse(storedData)
      fetchdata(JSON.parse(storedData))
      params["authToken"] = authToken
      const responsedata = await ConstructorEventPost(AuthenticateUser, params)
      if (responsedata === "authentication_success") { setuserAuthenticated(true) }
      else if (responsedata === "authentication_failed") { setuserAuthenticated(false); navigate("/signIn") }
      else { setuserAuthenticated(false); navigate("/signIn") }
    }
    else { setuserAuthenticated(false); navigate("/signIn") }
  }

  const fetchdata = async (params) => {
    const responsedata = await ConstructorEventGet(FetchHomePageDetails, params)
    sessionStorage.setItem('UserData', JSON.stringify(responsedata[0]))
    checkUserType(responsedata[0])
  }

  function checkUserType(data) {
    const userType = data && data.user_type
    if (userType === "mentor") {
      setVerifiedAsMentor(true)
    }
    else if (userType === "admin") {
      setVerifiedAsAdmin(true)
    } else if (userType === "BDE") {
      setVerifiedAsBDE(true)
    }
  }


  return (
    <div className="font-gilroy font-medium text-gray text-lg leading-[27px]">
      <ToastContainer />
      <AuthenticationState.Provider value={{ userAuthenticated, setuserAuthenticated, verifiedAsMentor, setVerifiedAsMentor, verifiedAsAdmin, setVerifiedAsAdmin, verifiedAsBDE, setVerifiedAsBDE }}>
        <Routes>
          <Route path="/codomo/home" element={userAuthenticated ? <HomePage /> : <Authentication />} />
          <Route path="/codomo/landing" element={userAuthenticated ? <AboutOne /> : <Authentication />} />
          <Route path="/codomo/course" element={userAuthenticated ? <Course /> : <Authentication />} />
          <Route path="/codomo/contactus" element={userAuthenticated ? <ContactUs /> : <Authentication />} />
          <Route path="/codomo/course/downloads" element={userAuthenticated ? <Downloads /> : <Authentication />} />
          <Route path="/codomo/blog" element={userAuthenticated ? <Blog /> : <Authentication />} />
          <Route path="/codomo/editprofile" element={userAuthenticated ? <EditProfile /> : <Authentication />} />
          <Route path="/codomo/codeeditor" element={userAuthenticated ? <AdvancedCourse /> : <Authentication />} />
          <Route path="/codomo/popup" element={userAuthenticated ? <Popup /> : <Authentication />} />
          <Route path="/codomo/ActivityOne" element={userAuthenticated ? <ActivityOne /> : <Authentication />} />
          <Route path="/codomo/ActivityOneOne" element={userAuthenticated ? <ActivityOneOne /> : <Authentication />} />
          <Route path="/codomo/ActivityTwo" element={userAuthenticated ? <ActivityTwo /> : <Authentication />} />
          <Route path="/codomo/ActivityTwoOne" element={userAuthenticated ? <ActivityTwoOne /> : <Authentication />} />
          <Route path="/codomo/ActivityThree" element={userAuthenticated ? <ActivityThree /> : <Authentication />} />
          <Route path="/codomo/StoryTellerActivity" element={userAuthenticated ? <StoryTellerActivity /> : <Authentication />} />
          <Route path="/codomo/StoryTellerActivityOne" element={userAuthenticated ? <StoryTellerActivityOne /> : <Authentication />} />
          <Route path="/codomo/ActivitySeven" element={userAuthenticated ? <ActivitySeven /> : <Authentication />} />
          <Route path="/codomo/ActivityEight" element={userAuthenticated ? <ActivityEight /> : <Authentication />} />
          <Route path="/codomo/ActivityNine" element={userAuthenticated ? <ActivityNine /> : <Authentication />} />
          <Route path="/codomo/ActivityFour" element={userAuthenticated ? <ActivityFour /> : <Authentication />} />
          <Route path="/codomo/ActivityFive" element={userAuthenticated ? <ActivityFive /> : <Authentication />} />
          <Route path="/codomo/ActivitySix" element={userAuthenticated ? <ActivitySix /> : <Authentication />} />
          <Route path="/codomo/ActivityTen" element={userAuthenticated ? <ActivityTen /> : <Authentication />} />
          <Route path="/codomo/ActivityEleven" element={userAuthenticated ? <ActivityEleven /> : <Authentication />} />
          <Route path="/codomo/AppLabSessionone" element={userAuthenticated ? <AppLabSessionone /> : <Authentication />} />
          <Route path="/codomo/Webusb" element={userAuthenticated ? <Webusb /> : <Authentication />} />
          <Route path="/codomo/AppLabSessionthree" element={userAuthenticated ? <AppLabSessionthree /> : <Authentication />} />
          <Route path="/codomo/AppLabSessionfour" element={userAuthenticated ? <AppLabSessionfour /> : <Authentication />} />
          <Route path="/codomo/AppLabSessionfive" element={userAuthenticated ? <AppLabSessionfive /> : <Authentication />} />
          <Route path="/codomo/AppLabSessionsix" element={userAuthenticated ? <AppLabSessionsix /> : <Authentication />} />
          <Route path="/codomo/AppLabSessionseven" element={userAuthenticated ? <AppLabSessionseven /> : <Authentication />} />
          <Route path="/codomo/AppLabSessioneight" element={userAuthenticated ? <AppLabSessioneight /> : <Authentication />} />
          <Route path="/codomo/AppLabSessionnine" element={userAuthenticated ? <AppLabSessionnine /> : <Authentication />} />
          <Route path="/codomo/AppLabSessionten" element={userAuthenticated ? <AppLabSessionten /> : <Authentication />} />
          <Route path="/codomo/AppLabSessioneleven" element={userAuthenticated ? <AppLabSessioneleven /> : <Authentication />} />
          <Route path="/codomo/onecompiler" element={userAuthenticated ? <OneCompilerIframe /> : <Authentication />} />

          <Route path="/codomo/mentor/activities" element={userAuthenticated ? verifiedAsMentor ? <MentorActivities /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          {/* <Route path="/codomo/mentor/addbatch" element={userAuthenticated ? verifiedAsAdmin ? <AddBatch /> : <Navigate to="/codomo/home"/> : <Authentication />} /> */}
          <Route path="/codomo/mentor/notification" element={userAuthenticated ? verifiedAsMentor ? <NotificationService /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/mentor/admiration" element={userAuthenticated ? verifiedAsMentor ? <Admiration /> : <Navigate to="/codomo/home" /> : <Authentication />} />

          {/* <Route path="/codomo/mentor/addstudent" element={userAuthenticated ?verifiedAsAdmin ?<AddStudent /> : <Navigate to="/codomo/home"/> : <Authentication />} /> */}
          <Route path="/codomo/mentor/addblog" element={userAuthenticated ? verifiedAsMentor ? <AddBlog /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/mentor/editbatch" element={userAuthenticated ? <EditBatch /> : <Authentication />} />



          <Route path="/codomo/admin/createbatch" element={userAuthenticated ? verifiedAsAdmin ? <CreateNewBatch /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/DemoDetails" element={userAuthenticated ? verifiedAsAdmin || verifiedAsBDE ? <DemoDetails /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/DemoDetailsForm" element={userAuthenticated ? verifiedAsAdmin || verifiedAsBDE ? <DemoDetailsForm /> : <Navigate to="/codomo/home" /> : <Authentication />} />

          <Route path="/codomo/admin/addbatch" element={userAuthenticated ? verifiedAsAdmin ? <AddBatch /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/addstudent" element={userAuthenticated ? verifiedAsAdmin ? <AddStudent /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/usermanagement" element={userAuthenticated ? verifiedAsAdmin ? <UserManagement /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/createnewuser" element={userAuthenticated ? verifiedAsAdmin ? <CreateNewUser /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/coursemanagement" element={userAuthenticated ? verifiedAsAdmin ? <CourseManagement /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/createnewcourse" element={userAuthenticated ? verifiedAsAdmin ? <CreateNewCourse /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/activitymanagement" element={userAuthenticated ? verifiedAsAdmin ? <ActivityManagement /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/downloadsmanagement" element={userAuthenticated ? verifiedAsAdmin ? <CourseDownloadsManagement /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/subactivitymanagement" element={userAuthenticated ? verifiedAsAdmin ? <SubActivityManagement /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/mappingmanagement" element={userAuthenticated ? verifiedAsAdmin ? <CourseMappingManagement /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/assiginingcoursetomentor" element={userAuthenticated ? verifiedAsAdmin ? <AssiginingCourseToMentor /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/batchmanagement" element={userAuthenticated ? verifiedAsAdmin ? <BatchManagement /> : <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/codomo/admin/assignBatch" element={<AssignBatchToTheMentor />} />
          <Route path="/codomo/admin/accountsmanagement" element={userAuthenticated ? verifiedAsAdmin ? <AccountsManagement /> : <Navigate to="/codomo/home" /> : <Authentication />} />



          <Route path="/" element={userAuthenticated ? <Navigate to="/codomo/home" /> : <Authentication />} />
          <Route path="/confirmemail" element={userAuthenticated ? <Navigate to="/codomo/home" /> : <EmailConfirm />} />
          <Route path="/signIn" element={userAuthenticated ? <Navigate to="/codomo/home" /> : <SignIn />} />
          <Route path="/signUp" element={userAuthenticated ? <Navigate to="/codomo/home" /> : <SignUp />} />
          <Route path="/videoCallActivity" element={<VideoCallActivity />} />
          <Route path="/voiceCallActivity" element={<VoiceCallActivity />} />
          <Route path="/chatScreen" element={<ChatScreen />} />
          <Route path="/googleMeet" element={<GoogleMeet />} />




          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </AuthenticationState.Provider>
    </div>
  );
}

export default App;