const awsGatewayId = process.env.REACT_APP_APIGATEWAY_ID;
const stage_name = process.env.REACT_APP_STAGE_NAME;
const aws_region = process.env.REACT_APP_AWS_REGION;
const api_body =
  "https://" +
  awsGatewayId +
  ".execute-api." +
  aws_region +
  ".amazonaws.com/" +
  stage_name +
  "/";

export const CheckUserDetailsForSignIn = api_body + "CheckUserDetailsForSignIn";
export const FetchHomePageDetails = api_body + "FetchHomePageDetails";
export const AddNewUser = api_body + "AddNewUser";
export const FetchCourseDetails = api_body + "FetchCourseDetails";
export const FetchUserCourseDetails = api_body + "FetchUserCourseDetails";
export const FetchDemoEnquiryDetails = api_body + "FetchDemoEnquiryDetails";
export const UpdateDemoEnquiryDetails = api_body + "UpdateDemoEnquiryDetails";

export const FetchJsonFromS3 = api_body + "FetchJsonFromS3";
export const VerifyEmailId = api_body + "VerifyEmailId";
export const AddReview = api_body + "AddReview";

export const ContactUsEmailSend = api_body + "ContactUsEmailSend";
export const DownloadFileFromS3 = api_body + "DownloadFileFromS3";
export const FetchUserProgressFromBatchTable =
  api_body + "FetchUserProgressFromBatchTable";
export const FetchSessionDetails = api_body + "FetchSessionDetails";
export const studentcameraimages = "https://tan6tgboda.execute-api.ap-south-1.amazonaws.com/dev/studentcameraimages";
export const StudentAdmiration = "https://tan6tgboda.execute-api.ap-south-1.amazonaws.com/dev/StudentAdmiration";
export const FetchMentorDetails = api_body + "FetchMentorDetails";
export const AuthenticateUser = api_body + "AuthenticateUser";
export const FetchBatchData = api_body + "FetchBatchData";
export const PublishMessageAsBatchNotification =
  api_body + "PublishMessageAsBatchNotification";
export const RetriveMessagesFromBatchNotification =
  api_body + "RetriveMessagesFromBatchNotification";
export const AddNewBatch = api_body + "AddNewBatch";
export const FetchStudentDetails = api_body + "FetchStudentDetails";
export const updateBatchStatus = api_body + "updateBatchStatus";
export const AssignCourseToMentor = api_body + "AssignCourseToMentor"

//advanced
export const Advanced =
  "https://k6hybtigmf.execute-api.ap-south-1.amazonaws.com/Dev";
//export const Advanced = 'https://'+awsGatewayId+'.execute-api.'+aws_region+'.amazonaws.com/Dev';
export const RetrieveBlog = api_body + "RetrieveBlog";
export const AddNewBlog = api_body + "AddNewBlog";
export const FetchUserDetailsForAdmin = api_body + "FetchUserDetailsForAdmin";
export const UpdateUserDetails = api_body + "UpdateUserDetails";
export const AssignBatchToMentor = api_body + "AssignBatchToMentor";

export const UpdatecourseDetails = api_body + "UpdateCourseDetails";
export const api_body_userdetails =
  "https://x20ri8lnog.execute-api.ap-south-1.amazonaws.com/dev/";
export const CreateUserDetails = api_body_userdetails + "CreateNewUserDetails";
export const UploadProfileImageToS3 = api_body + "UploadProfileImageToS3";
export const UpdateSingleClassOfBatch = api_body + "UpdateSingleClassOfBatch";
export const Uploadhex = api_body + "Uploadhex";
export const AddNewCourse = api_body + "AddNewCourse";
export const ShowStudentName = "https://tan6tgboda.execute-api.ap-south-1.amazonaws.com/dev/ShowStudentName";
export const UpdateCourseDetails = api_body + "UpdateCourseDetails";
export const FetchAllCourses = api_body + "FetchAllCourses";
export const DoodleCallingFunction = api_body + "DoodleCallingFunction";
export const StudentCloudFrontDistributionID =
  process.env.REACT_APP_STUDENT_CLOUDFRONT_DISTRIBUTION;
export const CourseCloudFrontDistributionID =
  process.env.REACT_APP_COURSE_CLOUDFRONT_DISTRIBUTION;
export const SaveHtmlStructure =
  "https://dt8ajhrk9b.execute-api.ap-south-1.amazonaws.com/dev/SaveHtmlStructure";